import React from 'react'
import { Container } from 'react-bootstrap'
import Img1 from '../assets/images/gallery/img-1.jpg'
import Img2 from '../assets/images/gallery/img-2.jpg'
import Img3 from '../assets/images/gallery/img-3.jpg'
import Img4 from '../assets/images/gallery/img-4.jpg'
import Img5 from '../assets/images/gallery/img-5.jpg'
import Img6 from '../assets/images/gallery/img-6.png'
import Img7 from '../assets/images/gallery/img-7.jpg'
import Img8 from '../assets/images/gallery/img-8.jpg'
import Img9 from '../assets/images/gallery/img-9.jpg'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const Gallery = () => {

    const itemData = [
        {
            img: Img1,
            title: 'Image',
            author: 'author',
            cols: 2,
        },
        {
            img: Img2,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
        {
            img: Img3,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
        {
            img: Img4,
            title: 'Image',
            author: 'author',
            cols: 2,
        },
        {
            img: Img5,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
        {
            img: Img6,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
        {
            img: Img7,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
        {
            img: Img8,
            title: 'Image',
            author: 'author',
            cols: 2,
        },
        {
            img: Img9,
            title: 'Image',
            author: 'author',
            cols: 1,
        },
    ];

    return (
        <section className='gallery'>
            <Container>
                <h2 className='text-center'>Gallery</h2>
                <ImageList cols={3} gap={20}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1}>
                            <img src={item.img} alt={item.title} className='img-fluid' />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Container>
        </section>
    )
}

export default Gallery