import React, { useEffect } from 'react'
import ManjiVagjiyani from '../assets/images/team/Manji-Vagjiyani.png'
import ShaileshVaghjiyani from '../assets/images/team/Shailesh-Vaghjiyani.png'
import DilipParmar from '../assets/images/team/Dilip-Parmar.png'
import DipeshJadeja from '../assets/images/team/Dipesh-Jadeja.png'
import IshwarSuthar from '../assets/images/team/Ishwar-Suthar.png'
import IshwarPatidar from '../assets/images/team/Ishwar-Patidar.png'
import KhushbuBaladha from '../assets/images/team/Khushbu-Baladha.png'
import KunalVyas from '../assets/images/team/Kunal-Vyas.png'
import PalakPatel from '../assets/images/team/Palak-Patel.png'
import PreetamsinghChauhan from '../assets/images/team/Preetamsingh-Chauhan.png'
import VidhyutMistry from '../assets/images/team/Vidhyut-Mistry.png'
import VimalParmar from '../assets/images/team/Vimal-Parmar.png'
import VishalKanojiya from '../assets/images/team/Vishal-Kanojiya.png'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../assets/images/banner-team.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurTeam = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <img src={Banner} alt="Slider" className='img-fluid' />
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">Our Team</h2>
                    <p>Our team is a collection of like-minded people who are proficient in their respective fields. They have a very vital role in completing a project effectively.We completely connect and constantly coordinate with our avant-grade squad in the process of blending and crafting imaginations and thereby delivering an originative output as a unit. Being an integral part of our company, we continuously involve them and gracefully take innovative suggestions that might be productive to our services delivered. The service commitment which we provide is the reflection of our harmonious relationship managed with each other.</p>
                </Container>
            </section>
            <section>
                <Container className='team'>
                    <Row className='text-center'>
                        <Col>
                            <img src={ShaileshVaghjiyani} alt="Manji Vagjiyani" className='img-fluid' />
                        </Col>
                        <Col>
                            <img src={ManjiVagjiyani} alt="Manji Vagjiyani" className='img-fluid' />
                        </Col>
                    </Row>
                    <p className='mt-5'>With an experience of over a decade, we strive persistently for an inventive perception. We try to go beyond the peripherals and envision an unborn architectural brilliance. We emphasize the integration of many finer details for realizing a better portrayal. We constantly evolve with technology for delivering a distinctive quality. We connect, communicate, and add values to a future reality. - Manji Vaghjiyani, Shailesh Vaghjiyani Directors</p>
                    <p className='mt-5'>- Manji Vaghjiyani, Shailesh Vaghjiyani</p>
                    <p><strong>Directors</strong></p>
                </Container>
            </section>
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">CG Artists</h2>
                    <div className='team'>
                        <Row className='text-center'>
                            <Col md={3}>
                                <img src={DilipParmar} alt="Dilip Parmar" className='img-fluid' />
                                <h3>Dilip Parmar</h3>
                            </Col>
                            <Col md={3}>
                                <img src={DipeshJadeja} alt="Dipesh Jadeja" className='img-fluid' />
                                <h3>Dipesh Jadeja</h3>
                            </Col>
                            <Col md={3}>
                                <img src={IshwarSuthar} alt="Ishwar Suthar" className='img-fluid' />
                                <h3>Ishwar Suthar</h3>
                            </Col>
                            <Col md={3}>
                                <img src={IshwarPatidar} alt="Ishwar Patidar" className='img-fluid' />
                                <h3>Ishwar Patidar</h3>
                            </Col>
                            <Col md={3}>
                                <img src={KhushbuBaladha} alt="Khushbu Baladha" className='img-fluid' />
                                <h3>Khushbu Baladha</h3>
                            </Col>
                            <Col md={3}>
                                <img src={KunalVyas} alt="Kunal Vyas" className='img-fluid' />
                                <h3>Kunal Vyas</h3>
                            </Col>
                            <Col md={3}>
                                <img src={PalakPatel} alt="Palak Patel" className='img-fluid' />
                                <h3>Palak Patel</h3>
                            </Col>
                            <Col md={3}>
                                <img src={PreetamsinghChauhan} alt="Preetamsingh Chauhan" className='img-fluid' />
                                <h3>Preetamsingh Chauhan</h3>
                            </Col>
                            <Col md={3}>
                                <img src={VidhyutMistry} alt="Vidhyut Mistry" className='img-fluid' />
                                <h3>Vidhyut Mistry</h3>
                            </Col>
                            <Col md={3}>
                                <img src={VimalParmar} alt="Vimal Parmar" className='img-fluid' />
                                <h3>Vimal Parmar</h3>
                            </Col>
                            <Col md={3}>
                                <img src={VishalKanojiya} alt="Vishal Kanojiya" className='img-fluid' />
                                <h3>Vishal Kanojiya</h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default OurTeam
