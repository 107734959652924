export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Projects',
        path: '/work'
    },
    // {
    //     label: '360 VR',
    //     path: '/360-virtual-tour'
    // },
    // {
    //     label: 'Animations/Films',
    //     path: '/animations-films'
    // },
    // {
    //     label: 'Clients',
    //     path: '/our-clients'
    // },
    // {
    //     label: 'Services',
    //     path: '/our-services'
    // },
    {
        label: 'About Us',
        path: '/about-us'
    },
    {
        label: 'Our Team',
        path: '/our-team'
    },
    // {
    //     label: 'Blog',
    //     path: '/blog-list'
    // },
    {
        label: 'Contact Us',
        path: '/contact-us'
    }
]
