import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import Item1 from '../assets/videos/impact-showreel.mp4'
import Item2 from '../assets/images/slider/slider-2.png'
import Item3 from '../assets/images/slider/slider-3.jpg'
import CircleIcon from '@mui/icons-material/Circle';

const Slider = () => {

    const slide1 = useRef(null);
    const slide2 = useRef(null);
    const slide3 = useRef(null);
    const handleSlide1 = (e) => {
        if (e.deltaY > 0) {
            window.scrollTo({
                top: slide2.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    const handleSlide2 = (e) => {
        if (e.deltaY > 0) {
            window.scrollTo({
                top: slide3.current.offsetTop,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({
                top: slide1.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    const handleSlide3 = (e) => {
        if (e.deltaY < 0) {
            window.scrollTo({
                top: slide2.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    const handleFirstDot = () => {
        window.scrollTo({
            top: slide1.current.offsetTop,
            behavior: 'smooth',
        });
    };
    const handleSecondDot = () => {
        window.scrollTo({
            top: slide2.current.offsetTop,
            behavior: 'smooth',
        });
    };
    const handleThirdDot = () => {
        window.scrollTo({
            top: slide3.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <div id="slider">
            <div onWheel={handleSlide1} ref={slide1} className='slider-container'>
                <video autoPlay muted loop>
                    <source src={Item1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="centered">
                    <ul>
                        <li className='active'><CircleIcon /></li>
                        <li onClick={handleSecondDot}><CircleIcon /></li>
                        <li onClick={handleThirdDot}><CircleIcon /></li>
                    </ul>
                </div>
            </div>
            <div onWheel={handleSlide2} ref={slide2} className='wrapper slider-container'>
                <img src={Item2} alt="Slider" />
                <div className="caption">
                    <h1>Exceptional Environment,</h1>
                    <h2>Extra-ordinary Experience</h2>
                    <Button>Know More</Button>
                </div>
                <div className="centered">
                    <ul>
                        <li onClick={handleFirstDot}><CircleIcon /></li>
                        <li className='active'><CircleIcon /></li>
                        <li onClick={handleThirdDot}><CircleIcon /></li>
                    </ul>
                </div>
            </div>
            <div onWheel={handleSlide3} ref={slide3} className='wrapper slider-container'>
                <img src={Item3} alt="Slider" />
                <div className="caption">
                    <h2>Visualization that imparts life, emotions & engagement to a computer-generated scene</h2>
                    <Button>Know More</Button>
                </div>
                <div className="centered">
                    <ul>
                        <li onClick={handleFirstDot}><CircleIcon /></li>
                        <li onClick={handleSecondDot}><CircleIcon /></li>
                        <li className='active'><CircleIcon /></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Slider
