import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../assets/images/banner-work.png'
import Sukhdham from '../assets/images/work/sukhdham-morbi.jpg'
import Urbana from '../assets/images/work/urbana.jpg'
import Suvarnabhoomi from '../assets/images/work/suvarnabhoomi.jpg'
import Venetian from '../assets/images/work/venetian.jpg'
import Anutham from '../assets/images/work/anutham.jpg'
import PrivateResidence from '../assets/images/work/private-residence.jpg'
import EkaayaResort from '../assets/images/work/ekaaya-resort.jpg'
import MerakiHills from '../assets/images/work/meraki-hills.jpg'
import Verantes from '../assets/images/work/verantes.jpg'
import SolitaireSky from '../assets/images/work/solitaire-sky.jpg'
import TheConfluence from '../assets/images/work/the-confluence.jpg'
import CoconutCove from '../assets/images/work/coconut-cove.jpg'
import { Link } from 'react-router-dom'

const Work = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <img src={Banner} alt="Slider" className='img-fluid' />
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">Our Work</h2>
                    <p>Nulla quis lorem ut libero malesuada feugiat. Curabitur aliquet quam id dui posuere blandit. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Praesent sapien massa, convallis a pellentesque nec.</p>
                </Container>
            </section>
            <section>
                <Container className='explore'>
                    <Row>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Sukhdham} alt="Sukhdham" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Sukhdham</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Urbana} alt="Urbana" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Urbana</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Suvarnabhoomi} alt="Suvarnabhoomi" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Suvarnabhoomi</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Venetian} alt="Venetian" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Venetian</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Anutham} alt="Anutham" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Anutham</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={PrivateResidence} alt="Private Residence" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Private Residence</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={EkaayaResort} alt="Ekaaya Resort" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Ekaaya Resort</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={MerakiHills} alt="MerakiHills" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>MerakiHills</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Verantes} alt="Verantes" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Verantes</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={SolitaireSky} alt="Solitaire Sky" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Solitaire Sky</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={TheConfluence} alt="The Confluence" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>The Confluence</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={CoconutCove} alt="Coconut Cove" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Coconut Cove</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Work
