import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Work from './pages/Work'
import OurTeam from './pages/OurTeam'
// Work
import Verantes from './pages/work/Verantes'

function App() {
    return (
        <div>
            <CustomCursor
                targets={['button', 'h3', '.menu-icon', 'a', '.slider-container ul li']}
                customClass='custom-cursor'
                dimensions={30}
                fill='#ffffff00'
                strokeWidth={1}
                strokeColor='#fff'
                smoothness={{
                    movement: 0.7,
                    scale: 0.1,
                    opacity: 0.2,
                }}
                targetOpacity={0.5}
                targetScale={2.5}
            />
            <Router>
                <Header />
                <Routes>
                    <Route exact path='/' element={<Homepage />} />
                    <Route exact path='/about-us' element={<AboutUs />} />
                    <Route exact path='/contact-us' element={<ContactUs />} />
                    <Route exact path='/work' element={<Work />} />
                    <Route exact path='/our-team' element={<OurTeam />} />
                    <Route exact path='/work/verantes' element={<Verantes />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
