import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Img1 from '../../assets/images/work/detail/verantes-1.png'
import Img2 from '../../assets/images/work/detail/verantes-2.png'
import Img3 from '../../assets/images/work/detail/verantes-3.png'
import Img4 from '../../assets/images/work/detail/verantes-4.png'
import Img5 from '../../assets/images/work/detail/verantes-5.png'
import Img6 from '../../assets/images/work/detail/verantes-6.png'
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const Verantes = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    const [openCopyright, setOpenCopyright] = useState(false);

    const handleOpenCopyright = () => {
        setOpenCopyright(true);
    };

    const handleCloseCopyright = () => {
        setOpenCopyright(false);
    };

    return (
        <div className='work-details'>
            <img src={Img1} alt="Slider" className='img-fluid' />
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <img src={Img2} alt="Project" className='img-fluid' />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <img src={Img3} alt="Project" className='img-fluid' />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <img src={Img4} alt="Project" className='img-fluid' />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <img src={Img5} alt="Project" className='img-fluid' />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <img src={Img6} alt="Project" className='img-fluid' />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8}>
                            <h1>Verantes</h1>
                            <span className='year'>2019</span>
                        </Col>
                        <Col xs={4} sm={4} md={4} className='text-end'>
                            <span className='copyright' onClick={handleOpenCopyright}>&copy; Copyright</span>
                        </Col>
                    </Row>
                    <div className="project-details">
                        <Row>
                            <Col md={7}>
                                <h3>Program &nbsp;&nbsp;|&nbsp;&nbsp; <span>Residenstial</span></h3>
                                <h3>Developer &nbsp;&nbsp;|&nbsp;&nbsp; <span>HRG Construction</span></h3>
                                <h3>Architect &nbsp;&nbsp;|&nbsp;&nbsp; <span>Scarlet Design Studio ( Mr. Kush Shah)</span></h3>
                            </Col>
                            <Col md={5}>
                                <h3>Location &nbsp;&nbsp;|&nbsp;&nbsp; <span>Zydus Road, Ahmedabad</span></h3>
                                <h3>Usage &nbsp;&nbsp;|&nbsp;&nbsp; <span>Marketing</span></h3>
                                <h3>Agency &nbsp;&nbsp;|&nbsp;&nbsp; <span>Impact 3D</span></h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Dialog
                open={openCopyright}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseCopyright}
                className='copyright-dialog'
            >
                <DialogTitle className='copyright'>Copyright Notice</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur non nulla sit amet nisl tempus
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCopyright} variant={'outlined'}>Okay</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Verantes