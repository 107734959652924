import React, { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../assets/images/banner-about.jpg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Abhikalpan from '../assets/images/clients/abhikalpan.png'
import AdsArchitect from '../assets/images/clients/ads-architect.png'
import GodrejProperties from '../assets/images/clients/godrej-properties.png'
import HirenPatel from '../assets/images/clients/hiren-patel.png'
import IshaGroup from '../assets/images/clients/isha-group.png'
import MahindraLifeSpaces from '../assets/images/clients/mahindra-lifespaces.png'
import Shaligram from '../assets/images/clients/shaligram.png'
import SheetalInfra from '../assets/images/clients/sheetal-infra.png'
import Shilp from '../assets/images/clients/shilp.png'
import Sureka from '../assets/images/clients/sureka.png'
import SwatiBuilding from '../assets/images/clients/swati-building.png'
import TrueValue from '../assets/images/clients/true-value.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const sliderRef = useRef();
    const next = () => {
        sliderRef.current.slickNext();
    }
    const previous = () => {
        sliderRef.current.slickPrev();
    }

    return (
        <div>
            <img src={Banner} alt="Slider" className='img-fluid' />
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">Who Are We</h2>
                    <h3 className='type-2 text-center'>About Us</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd est.</p>
                </Container>
            </section>
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">What We Provide</h2>
                    <h3 className='type-2 text-center'>Our Services</h3>
                    <p>Our services would help our clients in advertising a brand, promote product innovation, market the campaigns, building trust with the audience, and other every possible aspect of communication.</p>
                    <ul className='type-2'>
                        <li>CGI</li>
                        <li>Photomontage</li>
                        <li>Product Visualization</li>
                        <li>Animations</li>
                        <li>Cinemagraph</li>
                        <li>Timelapse</li>
                        <li>Camera Tracking</li>
                        <li>360 Virtual Tour</li>
                    </ul>
                </Container>
            </section>
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">Our Satisfied Clients</h2>
                    <h3 className='type-2 text-center'>Clients</h3>
                    <Row className='clients'>
                        <Col xs={6} md={3}>
                            <img src={Abhikalpan} alt="Abhikalpan" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={AdsArchitect} alt="AdsArchitect" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={GodrejProperties} alt="GodrejProperties" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={HirenPatel} alt="HirenPatel" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={IshaGroup} alt="IshaGroup" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={MahindraLifeSpaces} alt="MahindraLifeSpaces" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={Shaligram} alt="Shaligram" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={SheetalInfra} alt="SheetalInfra" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={Shilp} alt="Shilp" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={Sureka} alt="Sureka" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={SwatiBuilding} alt="SwatiBuilding" className='img-fluid' />
                        </Col>
                        <Col xs={6} md={3}>
                            <img src={TrueValue} alt="TrueValue" className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">What Our Clients Say</h2>
                    <h3 className='type-2 text-center'>Testimonials</h3>
                    <div className="testimonials text-center">
                        <Slider ref={sliderRef} {...settings}>
                            <div key={1}>
                                <p>Have you ever looked at a beautifully realized 3D depiction of a soon-to-be constructed work of architecture and wondered about the human behind the work? What does it take to go from concept sketch to photorealism? What do 3D rendering artists eat for lunch? These are the burning questions being answered by people who want to take a peek behind the curtain and see just how architectural visualization happens…… It is rare to find this kind of good work these days. Team Impact are a very specific breed of professionals – equipped with a rigid technical skill set to go along their artistic spirit. I would like to thank the team from IMPACT DESIGN for such amazing visualization work for our projects. Team impact has worked with us with various project ranging from various small private projects to large development / institutional projects. Dharam Patel – Art and Architecture Associates</p>
                                <h4>Dharam Patel - Art and Architecture Associates</h4>
                            </div>
                            <div key={2}>
                                <p>What does it take to go from concept sketch to photorealism? What do 3D rendering artists eat for lunch? These are the burning questions being answered by people who want to take a peek behind the curtain and see just how architectural visualization happens…… It is rare to find this kind of good work these days. Team Impact are a very specific breed of professionals – equipped with a rigid technical skill set to go along their artistic spirit. I would like to thank the team from IMPACT DESIGN for such amazing visualization work for our projects. Team impact has worked with us with various project ranging from various small private projects to large development / institutional projects. Dharam Patel – Art and Architecture Associates</p>
                                <h4>Dinesh Kerai</h4>
                            </div>
                            <div key={3}>
                                <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec sollicitudin molestie malesuada. Pellentesque in ipsum id orci porta dapibus. Proin eget tortor risus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                <h4>Dharam Patel - Art and Architecture Associates</h4>
                            </div>
                            <div key={4}>
                                <p>What does it take to go from concept sketch to photorealism? What do 3D rendering artists eat for lunch? These are the burning questions being answered by people who want to take a peek behind the curtain and see just how architectural visualization happens…… It is rare to find this kind of good work these days. Team Impact are a very specific breed of professionals – equipped with a rigid technical skill set to go along their artistic spirit. I would like to thank the team from IMPACT DESIGN for such amazing visualization work for our projects. Team impact has worked with us with various project ranging from various small private projects to large development / institutional projects. Dharam Patel – Art and Architecture Associates</p>
                                <h4>Dinesh Kerai</h4>
                            </div>
                        </Slider>
                        <div className='actions'>
                            <ArrowBackIcon className='float-start' onClick={previous} />
                            <ArrowForwardIcon className='float-end' onClick={next} />
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default AboutUs
