import { Chip, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Banner from '../assets/images/banner-contact.jpg'
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <img src={Banner} alt="Slider" className='img-fluid' />
            <section className='contact'>
                <Container>
                    <h2 className="type-2 text-center">Get In Touch</h2>
                    <Row>
                        <Col md={8}>
                            <form className="contact-form">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control type="text" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <PhoneInput country={'in'} specialLabel="Mobile Number *" inputProps={{ minLength: 3, maxLength: 18, required: true }} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email Id *</Form.Label>
                                            <Form.Control type="email" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Message *</Form.Label>
                                            <Form.Control as="textarea" rows={6} required />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button className='mt-5 w-100' variant="secondary">Send</Button>
                            </form>
                        </Col>
                        <Col md={4} className='my-auto'>
                            <div className="contact-info">
                                <Divider className='my-4'>
                                    <Chip label={<LocationOnIcon />} variant="outlined" />
                                </Divider>
                                <p>Gujarat <br />
                                    B 1603 Westgate, Nr. YMCA Club, <br />
                                    S.G. Highway, Ahmedabad – 380015, Gujarat, India. <br />
                                    Mobile : <a href="tel:+91 81402 91255">+91 81402 91255</a><br />
                                    Email : <a href="mailto:mail@impactdesign.co.in">mail@impactdesign.co.in</a></p>
                                <p className='mt-5'>Delhi NCR <br />
                                    Mobile : <a href="tel:+91 98991 14198">+91 98991 14198</a> <br />
                                    Email : <a href="mailto:info@impactdesign.co.in">info@impactdesign.co.in</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3672.549115114822!2d72.500576!3d23.003602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b307285f0666dc5!2sImpact%20Design%20(IMPACT3D)!5e0!3m2!1sen!2sin!4v1653904290508!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" title='Map Location' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Container>
            </section>
        </div>
    )
}

export default ContactUs