import React from 'react'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Col, Container, Row } from 'react-bootstrap'
import Logo from '../assets/images/logo/impact-3d-logo.png'

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <div id='footer'>
            <Container>
                <Row>
                    <Col md={4}>
                        <img src={Logo} alt="Logo" className='img-fluid' />
                        <ul className='social'>
                            <li><a href="#!"><InstagramIcon /></a></li>
                            <li><a href="#!"><YouTubeIcon /></a></li>
                            <li><a href="#!"><FacebookIcon /></a></li>
                            <li><a href="#!"><TwitterIcon /></a></li>
                        </ul>
                    </Col>
                    <Col md={4} className='branch'>
                        <h5>Gujarat</h5>
                        <a href="tel:+91 81402 91255">+91 81402 91255</a><br />
                        <a href="mailto:mail@impactdesign.co.in">mail@impactdesign.co.in</a>
                    </Col>
                    <Col md={4} className='branch-2'>
                        <h5>Delhi NCR</h5>
                        <a href="tel:+91 98991 14198">+91 98991 14198</a><br />
                        <a href="mailto:info@impactdesign.co.in">info@impactdesign.co.in</a>
                    </Col>
                </Row>
            </Container>
            <div className="copyright">
                <Container>
                    <Row>
                        <Col md={6}>
                            <p>© {currentYear} Impact Design. All Rights Reserved.</p>
                        </Col>
                        <Col md={6} className='links'>
                            <p>Terms &amp; Conditions | Sitemap</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer
