import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from '../components/Slider'
import Gallery from '../components/Gallery'
import Sukhdham from '../assets/images/work/sukhdham-morbi.jpg'
import Urbana from '../assets/images/work/urbana.jpg'
import Suvarnabhoomi from '../assets/images/work/suvarnabhoomi.jpg'
import { Link } from 'react-router-dom'

const Homepage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <Slider />
            <section>
                <Container className='text-center'>
                    <h2 className="type-2">Work Philosophy</h2>
                    <p>To connect everyone with the aesthetics of breathtaking architectural nuances in photorealistic images, we focus on directing a story in a frame. To make it perennial and quintessential, we intend to analyze the textures and materialization for giving a pure experience. We aim to blend minuscule architectural details for reflecting a magical depth. We experiment and permute persistently with our subject along with many elements like gradients, light effects, specific climatic mood, surrounding socio-cultural environment, etc for justifying and giving life to a minimal yet impactfully ideal scene.</p>
                </Container>
            </section>
            <section>
                <Container className='explore'>
                    <h2 className='text-center'>Explore</h2>
                    <Row>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Sukhdham} alt="Sukhdham" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Sukhdham</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Urbana} alt="Urbana" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Urbana</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <Link to="/work/verantes"><img src={Suvarnabhoomi} alt="Suvarnabhoomi" className='img-fluid' /></Link>
                            <div className="info">
                                <Link to="/work/verantes"><h3>Suvarnabhoomi</h3></Link>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam voluptua.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Gallery />
        </div>
    )
}

export default Homepage
