import React, { useState } from 'react'
import { menu } from '../Menu'
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/images/logo/impact-3d-logo.png'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, SwipeableDrawer, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const Header = () => {
    const [sideMenu, setSideMenu] = useState(false)
    const pathname = window.location.pathname
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenu({ sideMenu, 'left': open });
    };
    return (
        <div id='header' className={(pathname === '' || pathname === '/') ? 'header-home' : 'header'}>
            <Container>
                <SwipeableDrawer
                    open={sideMenu['left']}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    className='side-menu'
                >
                    <Box
                        sx={{ width: 600 }}
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <CloseIcon onClick={toggleDrawer(true)} className='close-icon' />
                        <div className="my-auto links text-end">
                            {menu.map((menu, index) => (
                                <Link key={index} to={menu.path}>
                                    {menu.label}
                                </Link>
                            ))}
                        </div>
                    </Box>
                </SwipeableDrawer>
                <Row>
                    <Col xs={2} md={4} className="menu-icon"><MenuIcon onClick={toggleDrawer(true)} /></Col>
                    <Col md={4} className='my-auto text-center'>
                        <Link to='/'><img src={Logo} alt="Logo" className="img-fluid logo" /></Link>
                    </Col>
                    <Col md={4} className='my-auto right text-end d-none d-lg-block'>
                        <Link to='contact-us'>
                            <Tooltip title="Contact Us">
                                <AlternateEmailIcon />
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Header